// :: MessageService
// :: Emits the `SNACKBAR_SHOW` event through a global bus
import EventBus from "@/config/bus";

/**
 * Receives an object containing a message and a color for the message and emits the `SNACKBAR_SHOW` message
 * @param {String} code - Code to search (SKU)
 */
export const searchResult = code => {
  EventBus.$emit("STOCK_SEARCH_RESULT", code);
};
