<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn
            icon
            dark
            @click="
              dialog = false;
              loader = true;
            "
            :loading="loader"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Scanner</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <app-quagga-scanner
            v-if="dialog"
            :onDetected="onDetectedCode"
            :manualDetected="manualDetectedCode"
          ></app-quagga-scanner>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { showMessage } from "@/services/MessageService";
import QuaggaScanner from "../app/QuaggaScannerSingle";
import { mapActions } from "vuex";
import EventBus from "@/config/bus";

export default {
  data() {
    return {
      dialog: false,
      loader: true
    };
  },
  mounted() {
    EventBus.$on("TOGGLE_BARCODE_SCANNER", () => {
      this.dialog = !this.dialog;
    });
    EventBus.$on("TOGGLE_LOADER", () => {
      this.loader = false;
    });
  },
  methods: {
    ...mapActions("app", ["resultDecoder"]),
    onDetectedCode(result) {
      this.resultDecoder(result.codeResult.code);
      showMessage({
        message: "Código identificado: " + result.codeResult.code,
        color: "success",
        timeout: 2500
      });
      this.dialog = false;
      this.loader = true;
    },
    manualDetectedCode(result) {
      this.resultDecoder(result);
      showMessage({
        message: "Código identificado: " + result,
        color: "success",
        timeout: 2500
      });
      this.dialog = false;
      this.loader = true;
    }
  },
  components: {
    AppQuaggaScanner: QuaggaScanner
  }
};
</script>
