// :: LoaderService
// :: Emits the loader message event through a global bus
import EventBus from "@/config/bus";

/**
 * show or hide dialog in scanner product barcode,
 * @returns {Function} Emits 'TOGGLE_BARCODE_SCANNER' in the EventBus
 */
export const toggleDialogBarcodeScanner = () =>
  EventBus.$emit("TOGGLE_BARCODE_SCANNER");
export const toggleLoader = () => EventBus.$emit("TOGGLE_LOADER");
export const toggleDialogFilter = () => EventBus.$emit("TOGGLE_DIALOG_FILTER");
export const toggleDialogEdit = () =>
  EventBus.$emit("TOGGLE_DIALOG_EDIT_POINTSALE");
export const toggleDialogDetail = () =>
  EventBus.$emit("TOGGLE_DIALOG_DETAIL_POINTSALE");
