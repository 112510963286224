<style>
.scanner-container.viewport {
  width: 100%;
  height: 180px;
  overflow: hidden;
}
.scanner-container.viewport {
  position: relative;
}
.scanner-container.viewport > canvas,
.scanner-container.viewport > video {
  max-width: 100%;
  width: 100%;
}
#interactive.viewport > canvas,
#interactive.viewport > video {
  max-width: 100%;
  width: 100%;
}
canvas.drawing,
canvas.drawingBuffer {
  position: absolute;
  left: 0;
  top: 0;
}
</style>

<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          right
          absolute
          fab
          v-bind="attrs"
          color="#27272791"
          class="absolute-device-camera-code"
          v-on="on"
          dark
          :loading="loadingDevices"
          small
        >
          <v-icon>camera_alt</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(device, index) in devices" :key="index">
          <v-list-item-title
            :class="{ whiteOrBlackText }"
            @click="selectedDevice(device)"
            >{{ device.label }}</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
    <div id="interactive" class="viewport scanner scanner-container">
      <video
        style=" position: absolute;top: 50%; right: 50%;transform: translate(50%,-50%);"
      ></video>
    </div>
    <v-text-field
      hide-details
      outlined
      label="Código"
      type="text"
      dense
      class="mt-2"
      append-icon="qr_code"
      v-model="inputCode"
      @click:append="searchProduct"
    ></v-text-field>
    <v-container>
      <p class="text-h6 mb-2">Tipo de Código</p>
      <p class="ma-0 caption">
        Si tienes un problema con la lectura del código trata de cambiarlo por
        otro tipo (Asegurate de que el código a escanear coincida lo más posible
        con la imagen de referencia).
      </p>
      <v-list>
        <v-list-item
          link
          class="grey lighten-3 my-2"
          v-for="(decoder, index) in decoderList"
          :key="index"
          @click="selectedCode(decoder.code)"
        >
          <v-list-item-avatar width="280px" tile height="60px">
            <v-img :src="decoder.photo" contain></v-img>
          </v-list-item-avatar>
          <v-list-item-title :class="{ whiteOrBlackText }">{{
            decoder.name
          }}</v-list-item-title>
          <div
            v-if="quaggaState"
            style="position: absolute; right:5px; top:7px"
          >
            <div v-if="quaggaState.decoder">
              <v-chip
                small
                color="primary"
                class="caption"
                v-if="quaggaState.decoder.readers[0] === decoder.code"
                >Activo</v-chip
              >
            </div>
          </div>
        </v-list-item>
      </v-list>
      <app-absolute-loader
        :progress="loader"
        :fixed="true"
        :color="$vuetify.theme.dark ? '' : 'grey'"
      ></app-absolute-loader>
    </v-container>
  </div>
</template>

<script>
import Quagga from "@ericblade/quagga2"; // v2
import AbsoluteLoader from "@/components/layout/AbsoluteLoader";
import { errorMessage, showMessage } from "@/services/MessageService";
import { toggleLoader } from "@/services/DialogsBus";

export default {
  data() {
    return {
      loader: false,
      decoderList: [
        {
          code: "ean_reader",
          name: "Code EAN",
          photo: require("@/assets/barcode/ean_reader.gif")
        },
        {
          code: "code_128_reader",
          name: "Code 128",
          photo: require("@/assets/barcode/code_128_reader.gif")
        },
        {
          code: "ean_8_reader",
          name: "Code EAN 8",
          photo: require("@/assets/barcode/ean_8_reader.gif")
        },
        {
          code: "code_39_reader",
          name: "Code 39",
          photo: require("@/assets/barcode/code_39_reader.gif")
        },
        {
          code: "code_39_vin_reader",
          name: "Code 39 VIN",
          photo: require("@/assets/barcode/code_39_vin_reader.png")
        },
        {
          code: "codabar_reader",
          name: "Code Codabar",
          photo: require("@/assets/barcode/codabar_reader.jpg")
        },
        {
          code: "upc_reader",
          name: "Code UPC",
          photo: require("@/assets/barcode/upc_reader.gif")
        },
        {
          code: "upc_e_reader",
          name: "Code UPC-E",
          photo: require("@/assets/barcode/upc_e_reader.gif")
        },
        {
          code: "i2of5_reader",
          name: "Code i2of5",
          photo: require("@/assets/barcode/i2of5_reader.jpg")
        },
        {
          code: "2of5_reader",
          name: "Code 2of5",
          photo: require("@/assets/barcode/2of5_reader.jpg")
        },
        {
          code: "code_93_reader",
          name: "Code 93",
          photo: require("@/assets/barcode/code_93_reader.gif")
        }
      ],
      inputCode: null,
      devices: [],
      loadingDevices: false,
      quaggaState: {
        inputStream: {
          name: "Live",
          type: "LiveStream",
          constraints: {
            facingMode: "environment",
            aspectRatio: {
              min: 1,
              max: 2
            }
          }
        },
        numOfWorkers: 4,
        frequency: 10,
        decoder: {
          readers: [this.readerType]
        }
      }
    };
  },
  mounted() {
    this.init();
  },
  destroyed() {
    Quagga.stop();
  },
  computed: {
    whiteOrBlackText() {
      return this.$vuetify.theme.dark ? "white--text" : "black--text";
    }
  },
  methods: {
    init() {
      this.loader = true;
      this.loadingDevices = true;
      Quagga.init(this.quaggaState, err => {
        if (err) {
          toggleLoader();
          return errorMessage({
            message: "Ocurrio un error: " + err
          });
        }
        this.checkCapabilities();
        this.getDevices();
        Quagga.start();
        this.loader = false;
        toggleLoader();
      });
      Quagga.onDetected(this.onDetected);
    },
    searchProduct() {
      if (this.inputCode) this.manualDetected(this.inputCode);
      else {
        showMessage({
          message: "Ingrese un código manualmente",
          color: "warning",
          timeout: 1000
        });
      }
    },
    reInit() {
      Quagga.stop();
      this.init();
    },
    stopDecoder() {
      Quagga.stop();
    },
    selectedCode(code) {
      this.quaggaState.decoder.readers = [code];
      this.reInit();
    },
    selectedDevice(device) {
      this.quaggaState.inputStream.constraints.deviceId = device.deviceId;
      showMessage({
        message: `Estas utilizando el dispositivo ${device.label}`,
        color: "info",
        timeout: 1000
      });
      this.reInit();
    },
    getDevices() {
      Quagga.CameraAccess.enumerateVideoDevices().then(devices => {
        this.devices = devices;
        this.loadingDevices = false;
      });
    },
    checkCapabilities() {
      var track = Quagga.CameraAccess.getActiveTrack();
      var capabilities = {};
      if (typeof track.getCapabilities === "function") {
        capabilities = track.getCapabilities();
      }
      if (capabilities.zoom) {
        const maxZoom = parseFloat(capabilities.zoom.max / 3).toFixed(1);
        track.applyConstraints({
          advanced: [{ zoom: maxZoom }]
        });
      }
    }
  },
  components: {
    AppAbsoluteLoader: AbsoluteLoader
  },
  props: {
    onDetected: {
      type: Function
    },
    onProcessed: {
      type: Function
    },
    manualDetected: {
      type: Function
    },
    readerType: {
      type: String,
      default: "ean_reader"
    }
  }
};
</script>
