<template>
  <v-card width="100%" class="mt-2 mb-2 card_background" id="barcode">
    <v-container>
      <h3 class="text-h6" v-if="typeAction === 'register'">
        Código <span class="caption">(SKU)</span>
      </h3>
      <h3 class="subtitle-1" v-if="typeAction === 'stock'">
        Código del producto
      </h3>
      <p class="ma-0 mb-1" v-if="typeAction === 'register'">
        Ingresa el código de barras del producto que deseas registrar
      </p>
      <v-text-field
        ref="barcode"
        class="mt-2"
        v-model="barcode"
        :rules="[requiredRule, isValidCode]"
        label="Código"
        outlined
        :loading="loading"
        @input="checkStore"
      >
        <template v-slot:append-outer>
          <v-btn icon @click="barCodeCamera" large>
            <v-icon size="36">
              qr_code_scanner
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:append v-if="barcode != ''">
          <v-fade-transition leave-absolute>
            <v-progress-circular
              v-if="loadingCheckStore"
              size="24"
              width="2"
              color="info"
              indeterminate
            ></v-progress-circular>
            <template v-else>
              <v-icon
                v-if="existProductInStore === false"
                size="24"
                color="error"
              >
                error
              </v-icon>
              <v-icon
                v-if="existProductInStore === true"
                size="24"
                color="success"
              >
                check_circle
              </v-icon>
            </template>
          </v-fade-transition>
        </template>
      </v-text-field>
      <v-slide-y-transition>
        <v-btn
          block
          color="primary"
          v-if="existProductInStore && typeAction === 'stock'"
          @click="stockAction"
          >Buscar</v-btn
        >
      </v-slide-y-transition>
    </v-container>
    <app-bar-code-camera></app-bar-code-camera>
  </v-card>
</template>

<script>
import { toggleDialogBarcodeScanner } from "@/services/DialogsBus";
import { searchResult } from "@/services/StockBus";
import { database } from "@/config/firebase";
import { errorMessage } from "@/services/MessageService";
import { required } from "@/services/ValidatorService";
import BarCodeCamera from "@/components/inventory/BarCodeCamera";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      barcode: "",
      requiredRule: required,
      loading: true,
      // :: Check store product
      store_shallow: [],
      loadingCheckStore: false,
      productDatabaseRef: null,
      existProductInStore: null,
      isValidCode: true
    };
  },
  mounted() {
    this.getShallowInventory();
  },
  props: ["typeAction"],
  computed: {
    ...mapState("app", ["barcodeResult"])
  },
  watch: {
    barcodeResult(newValue) {
      if (newValue) {
        this.barcode = newValue;
        this.checkStore(newValue);
        this.$vuetify.goTo(this.$refs.barcode, { offset: 120 });
      }
    },
    store_shallow(newValue) {
      if (newValue) {
        this.checkStore(this.barcode);
      }
    },
    barcode(newValue) {
      if (newValue) {
        this.resultSearchShallowProduct(newValue);
      }
    }
  },
  methods: {
    ...mapActions("app", ["resultSearchShallowProduct"]),
    checkStore(keyValue) {
      this.loadingCheckStore = true;
      if (keyValue in this.store_shallow) {
        this.existProductInStore = this.typeAction === "stock" ? true : false;
        if (this.typeAction === "register") {
          this.isValidCode = () => false || "Código ya registrado";
        } else {
          this.isValidCode = true;
        }
      } else {
        if (this.typeAction === "stock") {
          this.isValidCode = () => false || "Producto no encontrado";
        } else {
          this.isValidCode = true;
        }
        this.existProductInStore = this.typeAction === "stock" ? false : true;
      }
      setTimeout(() => {
        this.loadingCheckStore = false;
      }, 500);
    },
    barCodeCamera() {
      toggleDialogBarcodeScanner();
    },
    stockAction() {
      searchResult(this.barcode);
    },
    resetCode() {
      this.barcode = "";
    },
    async getShallowInventory() {
      try {
        this.productDatabaseRef = await database(`store_shallow`);
        this.productDatabaseRef.on("value", async snapshot => {
          if (snapshot.exists()) this.store_shallow = snapshot.val();
          else this.store_shallow = [];
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        errorMessage({
          message:
            "Upss algo salio mal al obtener la información del dashboard. Code:" +
            error
        });
      }
    },
    async removeListenerDatabase() {
      await this.productDatabaseRef.off("value");
      this.productDatabaseRef = null;
    }
  },
  beforeDestroy() {
    this.removeListenerDatabase();
  },
  components: {
    AppBarCodeCamera: BarCodeCamera
  }
};
</script>

<style>
div#barcode > div > div > div.v-input__append-outer {
  margin-top: 5px;
}
</style>
